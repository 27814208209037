:root {
  --pink: #ed008c;
  --blue: #01c0f3;
  --white: #ffffff;
  --red: #ff0000;
  --yellow: #ffdd00;
  --blk: #000105;

  .pink {
    color: var(--pink);
  }

  .blue {
    color: var(--blue);
  }

  .red {
    color: var(--red);
  }

  .white {
    color: var(--white);
  }

  .yellow {
    color: var(--yellow);
  }

  .blk {
    color: var(--blk);
  }

  // fonts

  .ot {
    font-family: "OTBrut", monospace;
  }
  .tf {
    font-family: "Tfmadloud";
  }

  --ff-tf: "Tfmadloud", sans-serif;
  --ff-ot: "OTBrut", sans-serif;

  
  .pnt {
    cursor: pointer;
  }

  .und {
    text-decoration: underline;
    &:hover {
      filter: brightness(0.85);
    }
  }

  .uptx {
    text-transform: uppercase;
  }

  .captx {
    text-transform: capitalize;
  }
}
