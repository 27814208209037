a {
  color: inherit;
  text-decoration: none;
}

ol,
ul,
li {
  list-style-type: none;
}
p {
  cursor: default;
}
* {
  box-sizing: border-box;
}


@font-face {
  font-family: "OTBrut";
  font-style: normal;
  font-weight: 100;
  src: url("../assets/fonts/OTBrut-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Tfmadloud";
  font-style: normal;
  font-weight: 100;
  src: url("../assets/fonts/tfmadloud.woff2") format("woff2");
}


p,
div {
  cursor: default;
}


.txtcen {
  text-align: center;
}

.darkenhov {
  transition: 0.1s ease-in-out;

  &:hover {
    transition: 0.1s ease-in-out;
    cursor: pointer;
    filter: brightness(0.8);
  }
}
