@import "mixins.scss";
@import "global.scss";
@import "reset.scss";
@import "variables.scss";

.hidden {
  // display: none;
}
.App {
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  // loading screen

  .loadingscreen {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 100000000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--purp);
    gap: 2rem;
    background-color: #ed008c;
    z-index: 5000;
    @include mobile {
      gap: 3rem;
    }
    .arrow {
      height: auto;
      width: 8.5rem;
      @include mobile {
        width: 5rem;
      }
    }
    .arrow1 {
      position: absolute;
      left: 35%;
      top: 50%;
      @include mobile {
        left: 15%;
        top: 50%;
      }
    }
    .arrow2 {
      position: absolute;
      right: 30%;
      bottom: 40%;
      rotate: 90deg;
      @include mobile {
        display: none;
      }
    }
    .arrow3 {
      position: absolute;
      left: 55%;
      bottom: 5%;

      rotate: 180deg;
      @include mobile {
        bottom: 15%;
      }
    }
    .enterbtn {
      background-color: #46c5ea;
      border: 0.3rem solid white;
      border-radius: 0.4rem;
      color: white;
      font-size: 1.5rem;
      padding: 0.6rem 3rem;
      cursor: pointer;
      transition: 0.2s ease-in-out;

      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4);
      @include mobile {
        transform: scale(0.85);
      }
      &:hover {
        transition: 0.2s ease-in-out;
        background-color: #b700ba;
      }
    }
    .tyface {
      height: auto;
      width: 16rem;
      @include mobile {
        width: 11rem;
      }
    }
  }

  //   navbar area
  .navbar {
    position: fixed;
    z-index: 500;
    top: 0.2rem;
    left: 1rem;
    display: flex;
    flex-direction: row;
    gap: 0;
    max-width: fit-content;
    transform: scale(0.85);
    @include mobile {
      top: -1rem;
      transform: scale(0.65);
      left: 0rem;
    }
    .navside {
      width: auto;

      height: 10rem;
      @include mobile {
        height: 7.5rem;
      }
    }
    .s1 {
      top: -0.5rem;
      position: absolute;
      left: -2rem;
      @include mobile {
        top: 1.5rem;
        left: -1.7rem;
      }
    }
    .sl {
      font-size: 1.5rem;
      @include mobile {
        font-size: 1rem;
      }
    }
    .s2 {
      top: -0.5rem;
      position: absolute;
      right: -2rem;
      @include mobile {
        top: 1.5rem;
        right: -1.7rem;
      }
    }
    .nav-cnt {
      padding: 1rem 2rem;
      display: flex;
      flex-direction: column;
      background-color: #46c5ea;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      @include mobile {
        margin-top: 2rem;
        padding: 1rem;
        gap: 0.5rem;
      }
      .top {
        font-size: 1rem;
        @include mobile {
          display: none;
        }
      }
      .mid {
        font-size: 1.5rem;
        letter-spacing: 5px;
        @include mobile {
          text-wrap: nowrap;
        }
      }
      .icons {
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
        .ico {
          font-size: 1.8rem;
          cursor: pointer;
        }
        .dx {
          width: auto;
          height: 2rem;
          cursor: pointer;
        }
      }
    }
  }

  .toparea {
    // margin-top: -9rem;
    height: 720px;
    width: 100%;
    background-image: url(../assets/imgs/area2/bg.jpg);
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    @include mobile {
      display: flex;
      flex-direction: column;
    }
    .crowd {
      position: absolute;
      bottom: -1rem;
      height: auto;
      width: 30rem;
      left: 9rem;
    }
    .blob {
      position: absolute;
      height: auto;
      width: 10rem;
      @include mobile {
        width: 8rem;
      }
    }
    .left {
      position: absolute;
      left: 3rem;
      display: flex;
      flex-direction: column;
      bottom: -2rem;
      @include mobile {
        display: none;
      }

      .drakebox {
        position: absolute;
        z-index: 10;
        height: auto;
        width: 14rem;
        bottom: 27rem;
        left: -1rem;
      }
      .pillcnt {
        display: flex;
        flex-direction: column;
      }
      .pillgrp {
        height: auto;
        width: 15rem;
        max-height: 100vh;
        overflow: hidden;
        margin-bottom: -30px;
        margin-left: -15px;
      }
    }

    .center {
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;
      width: 65rem;
      @include mobile {
        width: 22rem;
        margin-top: 4rem;
      }
    }

    .bottomcenter {
      position: absolute;
      height: auto;
      bottom: 0.5rem;
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      align-items: center;
      justify-content: center;
      @include mobile {
        flex-direction: column;
        bottom: 3rem;
      }
      .addy {
        width: auto;
        height: 2.75rem;
        &:hover {
          filter: brightness(0.8);
        }
        @include mobile {
          height: 1.3rem;
          background-color: #b700baaf;
          border-radius: 1rem;
        }
      }
      .contactbtn {
        background-color: #46c5ea;
        border: 0.3rem solid white;
        border-radius: 0.4rem;
        color: white;
        font-size: 1.1rem;
        padding: 0.7rem 2rem;
        cursor: pointer;
        transition: 0.2s ease-in-out;

        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4);
        @include mobile {
          transform: scale(0.7);
        }
        &:hover {
          transition: 0.2s ease-in-out;
          background-color: #b700ba;
        }
      }
    }

    .right {
      .circ-txt {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 4rem;
        right: 5rem;
        @include mobile {
          top: 3rem;
          left: -8rem;
          transform: scale(0.6);
        }
        .circ {
          height: auto;
          width: 13rem;
        }
        .name {
          position: absolute;
          height: auto;
          width: 15rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .buybtn {
        overflow: hidden;
        border-top-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        width: 13rem;
        height: auto;
        position: absolute;
        top: 32rem;
        right: 13rem;
        cursor: pointer;
        background-color: #ed008c;

        background: linear-gradient(90.12deg, #01a8fc 0.09%, #fd00ec 99.87%);

        border: 3px solid #000000;

        color: white;
        box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.34);
        display: flex;
        align-items: center;
        border: 0.3rem solid #ed008c;
        span {
          overflow: hidden;
          color: #fff;
          display: block;
          padding-left: 35px;
          text-transform: uppercase;
          font: bold 18px/66px Arial;
          transform: scaleX(0.6);
          letter-spacing: 3px;
          transform-origin: center left;
          transition: color 0.3s ease;
          position: relative;
          z-index: 1;
          font-size: 1.1rem;
          letter-spacing: 10px;
          text-wrap: nowrap;
        }
        em {
          position: absolute;
          height: 1px;
          background: #fff;
          width: 25%;
          right: 23px;
          top: 50%;
          transform: scaleX(0.25);
          transform-origin: center right;
          transition: all 0.3s ease;
          z-index: 1;
        }
        &:before,
        &:after {
          content: "";
          background: #fff;
          height: 50%;
          width: 0;
          position: absolute;
          transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        }
        &:before {
          top: 0;
          left: 0;
          right: auto;
        }
        &:after {
          bottom: 0;
          right: 0;
          left: auto;
        }
        &:hover {
          &:before {
            width: 100%;
            right: 0;
            left: auto;
          }
          &:after {
            width: 100%;
            left: 0;
            right: auto;
          }
          span {
            color: #000;
          }
          em {
            background: #000;
            transform: scaleX(1);
          }
        }

        @include mobile {
          position: relative;
          top: auto;
          right: auto;
          left: 50%;
          transform: translateX(-50%);
          bottom: -2rem;
          height: 3rem;
        }
        // &:hover {
        //   filter: brightness(0.8);
        // }
      }
    }
  }

  .midarea {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3.5rem solid;
    border-image: url(../assets/imgs/area2/bluetexture.jpg) 30 round;

    border-right-color: #ed008c;
    background-image: url(../assets/imgs/texture2.jpg);
    background-size: cover;
    background-position: center;
    position: relative;
    height: 720px;
    overflow: hidden;
    @include mobile {
      height: 400px;
      border: 2rem solid var(--pink);
    }
    .bottle1 {
      position: absolute;
      bottom: 15rem;
      z-index: 20;
      height: auto;
      width: 13rem;
      left: 0;
      @include mobile {
        display: none;
      }
    }

    .cmc {
      position: absolute;
      z-index: 49;
      border-radius: 4rem;
      height: auto;
      top: 3rem;
      left: 5rem;
      width: 10rem;
      background-color: white;
      padding: 5px;
      transition: 0.3s;

      &:hover {
        cursor: pointer;
        transition: 0.3s;
        filter: brightness(0.8);
        background-color: rgb(255, 59, 203);
        scale: 0.95;
      }
      @include mobile {
        width: 5rem;
        top: 1rem;
        left: 1rem;
      }
    }

    .cgk {
      position: absolute;
      z-index: 49;
      border-radius: 50%;
      height: auto;
      bottom: 3rem;
      right: 5rem;
      width: 9rem;
      background-color: white;
      padding: 5px;
      transition: 0.3s;

      &:hover {
        cursor: pointer;
        transition: 0.3s;
        filter: brightness(0.9);
        background-color: rgb(255, 59, 203);
        scale: 0.95;
      }
      @include mobile {
        width: 4.5rem;
        top: 1rem;
        right: 1rem;
      }
    }

    .pillshower {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      @include mobile {
        justify-content: center;
        align-items: center;
      }
      .pillgrp2 {
        margin-left: -7rem;
        // position: absolute;
        height: 45rem;
        width: auto;
        @include mobile {
          height: 30rem;
          width: auto;
        }
      }
    }
    .eye-cnt {
      z-index: 6;
      display: flex;
      align-items: center;
      justify-content: center;
      @include mobile {
        transform: scale(0.4);
      }
      .eye1 {
        width: auto;
        height: 15rem;
      }
      .pupil1 {
        height: 20rem;
        position: absolute;
        top: 41%;
        left: 52.5%;
        transform: translate(-50%, -50%);
        @include mobile {
          height: 30rem;
        }
      }
    }
  }

  .lowerarea {
    display: flex;
    flex-direction: row;
    background-image: url(../assets/imgs/texture44.jpg);
    background-size: cover;
    height: 900px;
    border: 1.5rem solid var(--blk);
    width: 100%;
    position: relative;
    @include mobile {
      height: auto;
    }

    .leftest {
      width: 10%;
      .tyface {
        height: auto;
        width: 9rem;
        @include mobile {
          display: none;
        }
      }
      .bottlearea {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        .bootle {
          height: auto;
          width: 7rem;
          rotate: 90deg;
          @include mobile {
            scale: 0.7;
            margin-left: -4rem;
          }
        }
      }
    }
    .center {
      width: 70%;
      display: flex;
      flex-direction: row;
      gap: 2rem;
      align-items: center;
      justify-content: center;
      position: relative;
      @include mobile {
        margin-top: 5rem;
        margin-bottom: 9rem;
        width: 95%;
      }
      .whatabout {
        position: absolute;
        width: auto;
        height: 8rem;
        z-index: 6;
        top: 2rem;
        left: 50%;
        transform: translateX(-50%);
        @include mobile {
          top: -3rem;
          height: 5rem;
        }
      }

      .tybox {
        position: absolute;
        width: auto;
        height: 11rem;
        z-index: 6;
        top: 50%;
        left: 57%;
        transform: translate(-50%, -50%);
        @include mobile {
          display: none;
        }
      }

      .pinkarea {
        position: relative;
        background-image: url(../assets/imgs/smtext1.png);
        border: 0.4rem solid var(--yellow);
        border-radius: 2rem;
        width: 430px;
        height: 700px;
        overflow: hidden;
        .starcnt {
          position: absolute;
          overflow: visible;
          // top: -7rem;
          // left: 0;
          .hp {
            position: absolute;

            left: 50%;
            transform: translate(-50%, -50%);

            text-align: center;
            display: none;
          }
          .star {
            height: auto;
            width: 11rem;
            position: absolute;
            top: -4rem;
            left: -3rem;
            overflow: visible;
          }
        }

        .pill {
          position: absolute;
          right: 7rem;
          height: auto;
          width: 11rem;
          top: 4rem;
          @include mobile {
            left: 50%;
            transform: translateX(-50%);
            width: 8rem;
            top: 5rem;
          }
        }

        .stats {
          height: auto;
          width: 20rem;
          position: absolute;
          left: 3rem;
          bottom: 12rem;
          @include mobile {
            bottom: 15rem;
            height: auto;
            width: 250px;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        .circ-txt {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          bottom: 8rem;
          left: 2rem;
          @include mobile {
            transform: scale(0.85);
            left: 0rem;
            bottom: 11rem;
          }
          .circ {
            height: auto;
            width: 11rem;
          }
          .txt {
            text-align: center;
            position: absolute;
            font-size: 1rem;
            width: 14rem;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .os {
          position: absolute;
          height: auto;
          width: 13rem;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .bluearea {
        position: relative;
        background-image: url(../assets/imgs/smtxt2.png);
        border: 0.4rem solid var(--pink);
        border-radius: 2rem;
        width: 430px;
        height: 700px;
        overflow: hidden;
        @include mobile {
          display: none;
        }
        .cloud {
          width: auto;
          height: 6rem;
          position: absolute;
        }
        .cloud1 {
          top: 3rem;
          left: 4rem;
        }
        .cloud2 {
          right: 2rem;
          top: 10rem;
        }
        .toronto {
          position: absolute;
          right: 0rem;
          top: 15rem;
          height: auto;
          width: 30rem;
          rotate: -84deg;
          right: -9.6rem;
        }
        .ob {
          position: absolute;
          height: auto;
          width: 10rem;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    .yellowarea {
      position: absolute;
      display: flex;
      // rotate: 90deg;
      text-wrap: nowrap;
      background-color: var(--yellow);
      border: 0.3rem solid var(--blk);
      font-size: 1.5rem;
      top: 25rem;
      padding: 0.2rem 2rem;
      padding-top: 0.4rem;
      border-radius: 2rem;
      rotate: 90deg;
      right: -9rem;
      @include mobile {
        rotate: 0deg;
        text-wrap: wrap;
        width: 300px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        padding: 0.2rem 0.8rem;
        font-size: 1.1rem;
        top: auto;
        right: auto;
        bottom: 2rem;
      }
    }

    .righest {
      width: 20%;
      @include mobile {
        display: none;
      }
      .cloudgrp {
        margin-left: 7rem;
        width: auto;
        height: 11rem;
      }
    }
  }

  .footerarea {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    @include mobile {
      display: flex;
      flex-direction: column;
    }
    .left {
      padding: 3rem 0rem;
      width: 75%;
      background-image: url(../assets/imgs/area2//bluetexture2.jpg);
      background-position: center;
      background-size: cover;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      @include mobile {
        padding: 0;
        width: 100%;
        padding-top: 5rem;
        padding-bottom: 2rem;
      }
      .circ-txt {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 4rem;
        left: 10rem;
        @include mobile {
          transform: scale(0.5);
          top: -2rem;
          left: -3rem;
        }
        .circ {
          height: auto;
          width: 15rem;
        }
        .txt {
          text-align: center;
          position: absolute;
          font-size: 1.1rem;
          width: 14rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .eye-cnt2 {
        z-index: 6;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        position: absolute;
        top: 3rem;
        left: 25rem;
        @include mobile {
          transform: scale(0.4);
          top: 1rem;
          left: 2rem;
        }
        .eye1 {
          width: auto;
          height: 8.5rem;
        }
        .pupil1 {
          height: 4rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .eye-cnt3 {
        z-index: 6;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        position: absolute;
        top: 3rem;
        left: 45rem;
        @include mobile {
          transform: scale(0.4);
          top: 0rem;
          left: 10rem;
        }
        .eye1 {
          width: auto;
          height: 8.5rem;
        }
        .eyelid {
          widows: auto;
          height: 9rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 7;
        }
        .pupil1 {
          height: 4rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .warntxt {
        text-wrap: nowrap;
        font-size: 1.5rem;
        padding: 0.3rem 4rem;
        border-radius: 2rem;
        text-align: center;
        position: absolute;
        bottom: 2rem;
        border: 2px solid var(--blk);
        background-color: var(--yellow);
        padding-top: 10px;
        text-transform: uppercase;
        @include mobile {
          display: none;
        }
      }

      .itemcombo {
        position: absolute;
        z-index: 10;
        height: auto;
        width: 22rem;
        top: 17rem;
        left: 44rem;
        @include mobile {
          width: 9rem;
          top: 12.5rem;
          left: 13.5rem;
          display: none;
        }
      }
      .face {
        height: 50rem;

        width: auto;
        @include mobile {
          height: 20rem;
        }
      }
    }

    .right {
      width: 25%;
      display: flex;
      flex-direction: column;
      border: 0.5rem solid var(--yellow);
      align-items: center;
      justify-content: space-between;
      background-color: var(--pink);
      border-right: 1rem solid var(--yellow);
      gap: 1rem;
      background-image: url(../assets/imgs/area2/endtexture.jpg);
      @include mobile {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-right: 0.5rem solid var(--yellow);
        height: 125px;
      }
      .name {
        margin-top: 1.5rem;
        width: auto;
        height: 5rem;
        @include mobile {
          display: none;
        }
      }

      .animarea {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mobile {
          margin-left: 3rem;
          rotate: 90deg;
        }
        .longcircle {
          width: auto;
          height: 40rem;
          @include mobile {
            height: 12rem;
          }
        }
        .icons {
          position: absolute;
          display: flex;
          flex-direction: column;
          gap: 3rem;
          color: #ef4136;
          align-items: center;
          justify-content: center;
          @include mobile {
            flex-direction: row;
            gap: 1.5rem;
            rotate: -90deg;
          }
          .ico {
            width: auto;
            height: 3rem;
            @include mobile {
              height: 1.5rem;
            }
          }
        }
      }

      .footernote {
        margin-bottom: 1rem;
        height: auto;
        width: 11rem;
        @include mobile {
          margin-top: 15px;
          margin-right: 0.6rem;
          width: 6rem;
        }
      }
    }
  }
}
